import React from "react";
import styled from "styled-components";

export const About = () => {
  const helloWorld = "Hi, I am Maқsat Jazbay";
  const aboutWorld = `
        I am a PhD student at Colorado School of Mines (CSM) in the Department of Geophysics, and a graduate of University of Texas at Austin (UT).
     `;
  const currentWorld = `
     Currently, I am looking for a new opportunities that match my background and interests. 
     `;
  return (
    <React.Fragment>
      <HelloView>{helloWorld}<Transcription> (Muhk Sat Juzz buy)</Transcription></HelloView>
      <DescriptionText>{aboutWorld}</DescriptionText>
      <DescriptionText>{currentWorld}</DescriptionText>
    </React.Fragment>
  );
};

const HelloView = styled.h1`
  font-family: Comic Sans MS;
  margin-top: 50px;
  text-align: center;
  font-weight: bold;
`;
const Transcription = styled.i`
  font-family: Comic Sans MS;
  margin-top: 50px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
`;

const DescriptionText = styled.div`
  font-family: Comic Sans MS;
  font-size: 20px;
  text-align: center;
`;
