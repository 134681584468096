import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

export const Layout = (props) => {
  return (
    <Styles>
      <Container>{props.children}</Container>
    </Styles>
  );
};

const Styles = styled.div`
  .container {
    background-color: #fff;
    max-width: 100%;
  }
`;
