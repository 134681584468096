import React from 'react'

export const NoMatch = () => {
    return (
    <div>
        <h2>
            Sorry, No Match!!!
        </h2>
    </div>    
    )
}