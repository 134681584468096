import { View } from "@react-pdf/renderer";
import React from "react";
import data from "../../../data/resume/resume.json";
import { BlueLine, SectionTitle } from "../helper";
import List, { MainBullet } from "../helper/List";

export const Skills = () => {
  const { skills } = data;
  return (
    <View style={{ flexDirection: "column" }}>
      <BlueLine style={{ marginTop: 5, marginBottom: 5 }} />
      <MainTitle />
      {renderSkills(skills)}
    </View>
  );
};

const MainTitle = () => <SectionTitle>Skills</SectionTitle>;

const renderSkills = (skills) =>
  skills.map((skill, id) => (
    <SkillSection key={id} name={skill.name} content={skill.content} />
  ));

const SkillSection = ({ name, content }) => (
  <List>
    <MainBullet>
      {name}: {content.join(", ")}
    </MainBullet>
  </List>
);
