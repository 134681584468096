import { View } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import React from "react";
import educationIcon from "../../../assets/resume/education.png";
import data from "../../../data/resume/resume.json";
import {
  BlueLine,
  BoldItalicText,
  BoldText,
  IconImage,
  RegularText,
} from "../helper";
import List, { MainBullet, SubBullet } from "../helper/List";

export const Education = () => {
  return <MainTitle />;
};

const MainTitle = () => {
  const { education } = data;
  return (
    <View>
      <BlueLine />
      <IconImage src={educationIcon} />
      <Major education={education} />
      <Minor education={education} />
    </View>
  );
};

const Major = ({ education }) => {
  return (
    <MajorView>
      <BoldText>{education.school}</BoldText>
      <RegularText>
        {education.city}, {education.state}
      </RegularText>
      <MajorText>{education.major}</MajorText>
    </MajorView>
  );
};

const Minor = ({ education }) => {
  return (
    <List>
      {education.minorDepartment.map((department, id) => {
        return (
          <React.Fragment key={`container_${id}`}>
            <MainBullet key={`mainBullet_${id}`}>{department}</MainBullet>
            <SubBullet key={`subBullet_${id}`}>{education.minor[id]}</SubBullet>
          </React.Fragment>
        );
      })}
    </List>
  );
};

const MajorView = styled.View`
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const MajorText = styled(BoldItalicText)`
  margin-top: 5px;
`;
