import styled from "@react-pdf/styled-components";

export const BlueLine = styled.View`
  background-color: #008fc8;
  width: 400;
  height: 3;
  margin-top: 35;
  margin-bottom: 30;
`;

export const IconImage = styled.Image`
  width: 80;
  height: 80;
  position: absolute;
  align-self: center;
`;

export const SectionTitle = styled.Text`
  text-align: center;
  font-weight: bold;
  font-family: TimesNewRoman;
`;

export const FramedContainer = styled.View`
  font-size: 12px;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  font-family: TimesNewRoman;
`;

export const RightFramedContainer = styled(FramedContainer)`
  margin-horizontal: 5px;
  margin-bottom: 2px;
`;

export const RegularText = styled.Text`
  font-size: 12px;
  font-family: TimesNewRoman;
`;

export const ItalicText = styled(RegularText)`
  font-style: italic;
`;

export const BoldText = styled(RegularText)`
  font-weight: bold;
`;

export const BoldItalicText = styled(BoldText)`
  font-style: italic;
`;
