import bilateral from "../../../assets/projects/bilateral_filter.png";
import seismic from "../../../assets/projects/data_processing.png";
import flix from "../../../assets/projects/flix.png";
import makstagram from "../../../assets/projects/makstagram.png";
import utorange from "../../../assets/projects/utorange.png";
import decon from "../../../assets/projects/imaging_conditions.jpg";
import digit_detection from "../../../assets/projects/digit_detection.png";

export const photos = [
  {
    src: seismic,
    width: 1,
    height: 1,
  },
  {
    src: bilateral,
    width: 1,
    height: 1,
  },
  {
    src: decon,
    width: 1,
    height: 1,
  },
  {
    src: digit_detection,
    width: 1,
    height: 1,
  },
  {
    src: flix,
    width: 1,
    height: 1,
  },
  {
    src: makstagram,
    width: 1,
    height: 1,
  },
  {
    src: utorange,
    width: 1,
    height: 1,
  },
];
