import React from "react";
import styled from "styled-components";

export const ProjectOverlay = ({
  className = "overlay",
  title = "Title",
  description = "Description",
  tools = "Tools",
  onClick = () => console.error("Missing event handler in ProjectOverlay"),
}) => {
  return (
    <MainContainer className={className} onClick={onClick}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Tools>{`Tools: ${tools}`}</Tools>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: blue;
  color: #ffffff;
  flex-direction: column;
  font-family: Comic Sans MS;
`;

const Title = styled.h1`
  font-size: 20px;
  align-self: center;
`;

const Description = styled.p``;

const Tools = styled.p``;
