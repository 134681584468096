import React from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import styled from "styled-components";
import data from "../../data/skills.json";
import { SubTitle, Title } from "../../utilities/helper";
import { WorkText } from "./Experience";

export const Skills = () => {
  return (
    <MainContainer>
      <Title id="skills">Skills</Title>
      <SectionContainer>
        <Row>
          {renderSection("Languages and Frameworks", data.languagesFrameworks)}
          {renderSection("Tools", data.tools)}
        </Row>
      </SectionContainer>
    </MainContainer>
  );
};

const levelToPercent = (level) => {
  return (level * 100) / 5;
};

//***IMPORTANT: don't have this section; READ AND DEVELOP IN FUTURE!! */
const renderSection = (name, data = {}) => {
  return (
    <Col>
      <SubTitle>{name}</SubTitle>
      {Object.keys(data).map((skill, id) => {
        return renderSkillProgressBar(skill, data, id);
      })}
    </Col>
  );
};

const renderSkillProgressBar = (skill, data, id) => {
  const progressLevel = data[skill];
  const percentLevel = levelToPercent(progressLevel);
  return (
    <Container key={`container_${id}`}>
      <Row>
        <Col sm={true}>
          <WorkText size={20}>{skill}</WorkText>
        </Col>
        <Col sm={true}>
          <ProgressBar
            variant="success"
            now={percentLevel}
            label={`${progressLevel}`}
          />
        </Col>
      </Row>
    </Container>
  );
};

const MainContainer = styled(Container)`
  flex: 1;
  padding: 0;
`;

const SectionContainer = styled(Container)`
  overflow: hidden;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #3981B2;
  margin-bottom: 30px;
`;
