import React from "react";
import styled from "styled-components";
import maksat from "../../../assets/resume/maksat_sf.gif";
import { Experience } from "../Experience";
import { Projects } from "../projects/Projects";
import { Skills } from "../Skills";
import { About } from "./About";
import gear from "../../../assets/gear/gear.svg";
import "../../../assets/gear/BrandGear.css";
import { Image } from "react-bootstrap";
import store from "../../../redux/store";
import { setSplashLoadingTo } from "../../../redux/actions";

const onLoad = (props) => {
  store.dispatch(setSplashLoadingTo(false));
};

export const Home = () => {
  return (
    <Styles>
      <GearContainer>
        <img src={gear} alt="logoImage" className="rotating" onLoad={onLoad} />
        <MyImage roundedCircle className="myImage" />
      </GearContainer>
      <About />
      <Experience />
      <Projects />
      <Skills />
    </Styles>
  );
};

const GearContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MyImage = styled(Image).attrs((props) => ({
  src: props.src || `${maksat}`,
  alt: "Logo",
}))`
  position: absolute;
  align-self: center;
  border-style: solid;
  border-color: #1c1c3d;
`;

const Styles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  padding-top: 10px;
`;
