import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import FadeIn from "./components/Animations/FadeIn";
import { CustomResume } from "./components/CustomResume/index";
import { Layout } from "./components/Layouts/Layout";
import { Experience } from "./components/NavBarItems/Experience";
import { Home } from "./components/NavBarItems/home/Home";
import { Projects } from "./components/NavBarItems/projects/Projects";
import { Skills } from "./components/NavBarItems/Skills";
import { NavigationBar } from "./components/Navigation/NavigationBar";
import { NoMatch } from "./components/NoMatch";
import withSplashScreen from "./components/SplashScreen/withSplashScreen";

import { Font } from "@react-pdf/renderer";
import ComputerFont from "./fonts/Computer_Style.ttf";
import TimesNewRomanBoldItalic from "./fonts/times-new-roman-bold-italic.ttf";
import TimesNewRomanBold from "./fonts/times-new-roman-bold.ttf";
import TimesNewRomanItalic from "./fonts/times-new-roman-italic.ttf";
import TimesNewRoman from "./fonts/times-new-roman.ttf";


const registerFont = () => {
  Font.register({
    family: "ComputerText",
    src: ComputerFont,
  });

  Font.register({
    family: "TimesNewRoman",
    fonts: [
      { src: TimesNewRoman },
      { src: TimesNewRomanBold, fontWeight: "bold" },
      { src: TimesNewRomanItalic, fontStyle: "italic" },
      { src: TimesNewRomanBoldItalic, fontStyle: "italic", fontWeight: "bold" },
    ],
  });
}

const App = () => {

  useEffect(() => {
    registerFont();
  }, []);

  return (
    <FadeIn>
      <React.Fragment>
        <NavigationBar />
        <Layout>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/experience" component={Experience} />
              <Route exact path="/projects" component={Projects} />
              <Route exact path="/skills" component={Skills} />
              <Route exact path="/resume" component={CustomResume} />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </Layout>
      </React.Fragment>
    </FadeIn>
  );
};

export default withSplashScreen(App);
