import styled from "@react-pdf/styled-components";
import React from "react";
// import maksatImg from "../../../assets/resume/maksat.png";
import maksatImg from "../../../assets/resume/maksat_sf.png";

export const Header = () => {
  return (
    <MainContainer>
      <SubContainer>
        <NameTitle>Maksat Jazbay</NameTitle>
        <OccupationTitle>Geophysicist and Software Engineer</OccupationTitle>
      </SubContainer>
      <MyImage src={maksatImg} />
    </MainContainer>
  );
};

const MainContainer = styled.View`
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding-top: 10px;
`;

const SubContainer = styled.View`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-horizontal: 5px;
  border-width: 3;
  border-color: #6e6e6e;
  border-style: solid;
`;

const NameTitle = styled.Text`
  color: #008fc8;
  font-family: TimesNewRoman;
`;

const OccupationTitle = styled(NameTitle)`
  color: #6e6e6e;
`;

const MyImage = styled.Image`
  height: 250;
  width: 200;
`;
