import { SET_SPLASH_LOADING } from "./actions";

const initialState = {
  splashLoading: true,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPLASH_LOADING:
      return { ...state, splashLoading: action.isLoading };
    default:
      return state;
  }
};

export default globalReducer;
