import { Link } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import React from "react";
import data from "../../../data/resume/workHistory.json";
import {
  BoldText,
  ItalicText,
  RightFramedContainer,
  SectionTitle,
} from "../helper";
import List, { MainBullet } from "../helper/List";

export const WorkHistory = () => {
  return (
    <React.Fragment>
      <MainTitle>Work History</MainTitle>
      <WorkHistories data={data} />
    </React.Fragment>
  );
};

const WorkHistories = ({ data }) =>
  data.map((company, id) => <WorkHistoryView key={id} company={company} />);

const WorkHistoryView = ({ company }) => {
  const { projects } = company;
  return (
    <RightFramedContainer>
      <CompanyInfo data={company} />
      {projects.map((project, id) => {
        return <Project key={id} data={project} />;
      })}
    </RightFramedContainer>
  );
};

const CompanyInfo = ({ data }) => {
  const { name: companyName, position, location } = data;
  return (
    <CompanyPositionView>
      <BoldText>{companyName}</BoldText>
      <ItalicText>{position}</ItalicText>
      <LocationText>{location}</LocationText>
    </CompanyPositionView>
  );
};

const Project = ({ data }) => {
  const { name, date, descriptions, link } = data;
  return (
    <React.Fragment>
      <ProjectHeader name={name} date={date} link={link} />
      <ProjectBody descriptions={descriptions} />
    </React.Fragment>
  );
};

const ProjectHeader = ({ name, date, link }) => (
  <Link src={link}>
    <ProjectHeaderView>
      <ProjectHeaderText>{name}</ProjectHeaderText>
      <ProjectHeaderText>{date}</ProjectHeaderText>
    </ProjectHeaderView>
  </Link>
);

const ProjectBody = ({ descriptions }) => (
  <List>
    {descriptions.map((description, id) => (
      <MainBullet key={id}>{description}</MainBullet>
    ))}
  </List>
);

const MainTitle = styled(SectionTitle)`
  ${"" /* margin: 10px; */}
`;

const LocationText = styled(ItalicText)`
  position: absolute;
  right: 3;
  font-size: 10;
`;

const CompanyPositionView = styled.View`
  align-items: center;
  justify-content: center;
`;

const ProjectHeaderView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-horizontal: 5px;
  margin-bottom: 5px;
  color: blue;
`;

const ProjectHeaderText = styled(ItalicText)`
  font-size: 11px;
`;
