import styled from "@react-pdf/styled-components";
import React from "react";
import { RegularText } from ".";

const List = ({ children }) => children;

export default List;

export const MainBullet = ({ children }) => (
  <BulletView>
    <BulletPoint>•</BulletPoint>
    <BulletContent>{children}</BulletContent>
  </BulletView>
);

export const SubBullet = ({ children }) => (
  <SubBulletView>
    <BulletPoint>•</BulletPoint>
    <SubBulletContent>{children}</SubBulletContent>
  </SubBulletView>
);

const BulletView = styled.View`
  flex-direction: row;
  margin-bottom: 5px;
  margin-horizontal: 5px;
`;

const SubBulletView = styled.View`
  flex-direction: row;
  margin-bottom: 5px;
  margin-left: 25px;
`;

const BulletPoint = styled.Text`
  width: 10px;
  font-size: 10px;
`;

const BulletContent = styled(RegularText)`
  flex: 1;
  font-size: 10px;
  text-align: justify;
`;

const SubBulletContent = styled(BulletContent)`
  font-weight: bold;
`;
