import React from "react";
import { fadeIn } from "react-animations";
import styled, { keyframes } from "styled-components";

const FadeIn = (props) => (
  <FadeInDiv duration={props.duration}>{props.children}</FadeInDiv>
);

const FadeInAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 5s ${FadeInAnimation};
`;
export default FadeIn;
