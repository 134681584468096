import { Document } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import React from "react";
import { LeftSection } from "./LeftSection/index";
import { RightSection } from "./RightSection/index";

// Create Document Component
export const Resume = () => (
  <Document>
    <MainPage size="A4">
      <LeftSection />
      <RightSection />
    </MainPage>
  </Document>
);
const MainPage = styled.Page`
  flex-direction: row;
  background-color: #e4e4e4;
`;
