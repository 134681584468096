import React from "react";
import styled from "styled-components";
import { ProjectOverlay } from "./ProjectOverlay";

const SelectedImage = ({
  index,
  photo,
  margin,
  direction,
  top,
  left,
  overlayData,
  onClick,
}) => {
  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  const { shortTitle, shortDescription, tools } = overlayData;

  return (
    <Styles>
      <ImageContainer
        style={{
          margin,
          ...cont,
        }}
        className="image-container"
      >
        <Image alt={photo.title} {...photo} />
        <ProjectOverlay
          className="overlay"
          title={shortTitle}
          description={shortDescription}
          tools={tools}
          onClick={onClick}
        />
      </ImageContainer>
    </Styles>
  );
};

export default SelectedImage;

const Image = styled.img`
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #3981B2;
`;

const ImageContainer = styled.div.attrs((props) => props.style)``;

const Styles = styled.div`
  .image-container: hover {
    outline: 2px solid #06befa border-radius: 20px border-width: 2px;

    .overlay {
      width: 100%;
      opacity: 0.7;
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    width: 0;
    height: 100%;
    background: blue;
    opacity: 0;
    transition: 0.7s ease;

    border-radius: 20px;
    border-width: 2px;
  }
`;

const cont = {
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
};
