import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import React, { useEffect } from "react";
import styled from "styled-components";
import { setSplashLoadingTo } from "../../redux/actions";
import store from "../../redux/store";
import { Resume } from "./Resume";

export const CustomResume = () => {
  const { splashLoading } = store.getState();

  useEffect(() => {
    if (splashLoading)
      setTimeout(() => {
        store.dispatch(setSplashLoadingTo(false));
      }, 5000);
  });

  return (
    <MainContainer>
      <DownloadView>
        <PDFDownloadLink document={<Resume />} fileName="MaksatJazbay.pdf">
          {({ blob, url, loading, error }) => {
            return loading ? "Loading document..." : "Download now!";
          }}
        </PDFDownloadLink>
      </DownloadView>
      <PDFViewer style={{ width: "70vw", height: "70vh" }}>
        <Resume />
      </PDFViewer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DownloadView = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: rgb(241, 123, 65);
  font-size: 30px;
  border-radius: 25px;
  padding-right: 5px;
  padding-left: 5px;
`;
