import React from "react";
import { Container } from "react-bootstrap";
import Gallery from "react-photo-gallery";
import styled from "styled-components";
import { SubTitle, Title } from "../../../utilities/helper";
import SelectedImage from "./SelectedImage";
import data from "../../../data/projects.json";
import { photos } from "./photos";

data.sort((a, b) => (a.category > b.category ? -1 : 1));

export const Projects = () => {
  return (
    <>
      <Title id="projects">Projects</Title>
      <SubTitle>Click on one to learn more!</SubTitle>
      {renderProjectList()}
    </>
  );
};

const renderProjectList = () => {
  return (
    <ProjectListContainer>
      <Gallery photos={photos} renderImage={imageRenderer} />
    </ProjectListContainer>
  );
};

const imageRenderer = ({ index, left, top, key, photo }) => (
  <SelectedImage
    key={key}
    margin={2}
    index={index}
    photo={photo}
    left={left}
    top={top}
    overlayData={data[index]}
    onClick={(event) => handleOnClick(event, index)}
  />
);

const handleOnClick = (event, index) => {
  return window.open(data[index].link, `linkToProject_${index}`);
};

const ProjectListContainer = styled(Container)`
  flex: 1;
  padding: 0;
`;
