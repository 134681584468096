import React from "react";
import { Container } from "react-bootstrap";
import ShowMoreText from "react-show-more-text";
import styled from "styled-components";
import imagePlaceholder from "../../assets/background-images/image_placeholder.png";
import adaiMaksat from "../../assets/experience/adaiMaksat.svg";
import widesense from "../../assets/experience/widesense.jpg"
import fastingCulture from "../../assets/experience/fastingculture.jpg";
import data from "../../data/resume/workHistory.json"
import { Title } from "../../utilities/helper";

const images = [widesense, fastingCulture, adaiMaksat];

export const Experience = () => {
  return (
    <MainContainer>
      <Title id="experience">Experience</Title>
      <CompanySection />
    </MainContainer>
  );
};

const CompanySection = () => {
  return data.map((companyData, id) => {
    if (!(companyData.category === "work")) return;

    const logoImage = images[id];

    //
    //TO DO: load the images dynamically with logo//
    //

    return (
      <CompanySectionContainer key={`container_${id}`} onClick={(event) => handleOnClick(event, id)}>
        <CompanyLogo key={`logo_${id}`} src={logoImage} />
        <CompanyInfo key={`info_${id}`} data={companyData} />
      </CompanySectionContainer>
    );
  });
};

const handleOnClick = (event, index) => {
  const link = data[index].projects[0].workLink || data[index].projects[0].link;
  return window.open(link, `linkToProject_${index}`);
};

const CompanyInfo = ({ data }) => {
  const { position, name: companyName, duration: workDuration, projects } = data;
  const items = [
    { size: 30, content: position, style: {} },
    { size: 25, content: companyName, style: {} },
    { size: 20, content: workDuration, style: { fontStyle: "italic" } },
  ];

  const workDescription = projects[0].descriptions.reduce((prevVal, curVal) => prevVal + ". " + curVal)

  return (
    <Container>
      {items.map((item, id) => (
        <WorkText key={`titles${id}`} size={item.size} style={item.style}>
          {item.content}
        </WorkText>
      ))}
      <WorkDescription text={workDescription} />
    </Container>
  );
};

const WorkDescription = ({ text }) => {
  return (
    <WorkText size={20}>
      <ShowMoreText
        lines={2}
        more="Show more"
        less="Show less"
        anchorClass="showMore"
        expanded={false}
      >
        {text}
      </ShowMoreText>
    </WorkText>
  );
};

const MainContainer = styled(Container)`
  flex: 1;
  padding: 0;
`;

const CompanySectionContainer = styled.div`
  margin-top: 10px;
  display: flex;
  overflow: hidden;
  background-color: white;
  border-radius: 20px;
  border-width: 2px;
  border-style: solid;
  border-color: #3981B2;
`;

export const WorkText = styled.div.attrs((props) => ({ style: props.style }))`
  font-size: ${(props) => props.size}px;
  font-family: Comic Sans MS;
`;

const CompanyLogo = styled.img.attrs((props) => ({
  src: props.src || imagePlaceholder,
  alt: "companyLogo",
}))`
  height: 100px;
  margin-left: 10px;
  margin-right: 10px;
  align-self: center;
`;
