import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import { NavBarLogo } from "../Gear/NavBarLogo";

export const NavigationBar = () => {
  const navbarItems = ["experience", "projects", "skills", "resume"];
  const gearSize = 50;
  return (
    <Styles>
      <Navbar expand="lg">
        {renderBrandGearsAndInitials(gearSize)}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {renderNavBarItems(navbarItems)}
      </Navbar>
    </Styles>
  );
};

const renderBrandGearsAndInitials = (gearSize) => {
  return (
    <Navbar.Brand href="/">
      <NavBarLogo size={gearSize} />
    </Navbar.Brand>
  );
};

const renderNavBarItems = (items) => {
  return (
    <Navbar.Collapse id="basic-navbar-nav">
      <NavContainer className="mr-auto">
        {items.map((item, id) => {
          const href = item === "resume" ? `/${item}` : `#${item}`;
          return (
            <NavLink key={id} href={href}>
              {item}
            </NavLink>
          );
        })}
      </NavContainer>
    </Navbar.Collapse>
  );
};

const NavContainer = styled(Nav)`
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const NavLink = styled(Nav.Link)`
  font-size: 20px;
  font-family: Comic Sans MS;
`;

const Styles = styled.div`
  .navbar {
    background-color: rgb(57, 129, 178);
    width: 100%;
  }
  .navbar-brand,
  .navbar-nav .nav-link {
    color: black;

    &: hover {
      color: white;
    }
  }
`;

///// OLD LOGO ////
// const myInitials = "MZ"; //old unused logo type
// //old unused logo type
// const renderBrandGearAndName = (initials, gearSize) => {
//   return (
//     <Navbar.Brand href="/">
//       <h1
//         style={{
//           fontFamily: "Mechanical",
//           position: "absolute",
//           top: 28,
//           left: 30,
//           fontSize: 12.5,
//         }}
//       >
//         {initials}
//       </h1>
//       <BrandGear size={gearSize} />
//     </Navbar.Brand>
//   );
// };
// //old unused logo type
