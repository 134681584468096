import styled from "@react-pdf/styled-components";
import React from "react";
import { WorkHistory } from "./WorkHistory";

export const RightSection = () => {
  return (
    <MainContainer>
      <WorkHistory />
      {/* <HonorsAwards /> */}
    </MainContainer>
  );
};

const MainContainer = styled.View`
  background-color: white;
  flex: 1;
`;
