import React, { Component } from "react";
import "./splash-screen.css";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";

export const LoadingMessage = ({ isLoading }) => {
  return (
    <div
      className="splash-screen"
      style={{ display: isLoading ? "flex" : "none" }}
    >
      {/* <div className="loading-dot">.</div> */}
      <Spinner animation="border" />
    </div>
  );
};

const withSplashScreen = (WrappedComponent, passProps = {}) => {
  class SplashContainer extends Component {
    render() {
      return (
        <React.Fragment>
          <LoadingMessage isLoading={this.props.isLoading} />
          <div style={{ display: this.props.isLoading ? "none" : "block" }}>
            <WrappedComponent {...this.props} />
          </div>
        </React.Fragment>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      isLoading: state.splashLoading,
    };
  };
  return connect(mapStateToProps)(SplashContainer);
};

export default withSplashScreen;
