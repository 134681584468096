import styled from "styled-components";

//current window width and height
const windowHeight = window.innerHeight;
const windowWidth = window.innerWidth;
const scale = windowWidth / windowHeight;

// get scaled size according to current window size

export const getScaledSize = (width) => {
  return scale * width;
};

export const Title = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Comic Sans MS;
  margin-top: 50px;
`;

export const SubTitle = styled.h2`
  font-family: Comic Sans MS;
  text-align: center;
`;
