import { Link } from "@react-pdf/renderer";
import styled from "@react-pdf/styled-components";
import React from "react";
import data from "../../../data/resume/resume.json";
import {
  BlueLine,
  FramedContainer,
  RegularText,
  SectionTitle,
} from "../helper";

export const ContactMe = () => {
  const { contacts } = data;
  return (
    <React.Fragment>
      <BlueLine style={{ marginTop: 5, marginBottom: 5 }} />
      <SectionTitle>Contact Me</SectionTitle>
      <Container>
        {contacts.map((contact, id) => (
          <Content
            key={`content_${id}`}
            content={contact.content}
            link={contact.link}
          />
        ))}
      </Container>
    </React.Fragment>
  );
};

const Content = ({ content, link = "" }) => {
  if (!link) {
    return <RegularText>{content}</RegularText>;
  }
  return (
    <Link src={link}>
      <RegularText style={{ color: "blue" }}>{content}</RegularText>
    </Link>
  );
};

const Container = styled(FramedContainer)`
  justify-content: center;
  align-items: center;
  margin-horizontal: 5px;
  padding-vertical: 5px;
`;
