import styled from "@react-pdf/styled-components";
import React from "react";
import { Address } from "./Address";
import { ContactMe } from "./ContactMe";
import { Education } from "./Education";
import { Header } from "./Header";
import { Skills } from "./Skills";

export const LeftSection = () => {
  return (
    <MainContainer>
      <Header />
      <Address />
      <Education />
      <Skills />
      <ContactMe />
    </MainContainer>
  );
};

const MainContainer = styled.View`
  background-color: #f17b41;
  flex: 1;
  flex-direction: column;
`;
