import styled from "@react-pdf/styled-components";
import React from "react";
import homePic from "../../../assets/resume/homeaddress.png";
import data from "../../../data/resume/resume.json";
import { BlueLine, BoldText, IconImage } from "../helper";

export const Address = () => {
  const { address } = data;
  return (
    <MainContainer>
      <BlueLine />
      <IconImage src={homePic} />
      {/* <BoldText>{address.address}</BoldText> */}
      <BoldText>
        {address.city}, {address.state}, {address.zip}
      </BoldText>
    </MainContainer>
  );
};

const MainContainer = styled.View`
  text-align: center;
`;
